<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      class="body-container-60"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div class="text-h1 font-weight-black mb-5">
          Solicitudes de proveedores
        </div>

        <v-tabs
          v-model="tab"
          color="secondary"
          background-color="transparent"
          show-arrows
          slider-size="5"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="(item, i) in tabs"
            :key="i"
            class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
            :ripple="false"
            :active-class="'font-weight-bold'"
          >
            {{ item.tabTitle }}
          </v-tab>
        </v-tabs>
        <v-divider color="secondary"></v-divider>

        <v-row justify-md="space-between" class="mx-0 my-5">
          <v-col cols="12" md="6">
            <div class="text-h3 text-md-h2 font-weight-black">
              {{ tabs[tab].title }}
            </div>
            <div v-if="!loading" class="text-h4">
              {{ filterRequests.length }}
              {{
                filterRequests.length == 1
                  ? " solicitud encontrada"
                  : " solicitudes encontradas"
              }}
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <!-- <SearchField
              label="Proveedor/Servicio"
              placeholder="¿Qué buscas?"
            /> -->
          </v-col>
        </v-row>

        <div v-if="!loading" class="mb-10">
          <ServiceCardList :services="filterRequests" type="request" />
        </div>
        <v-container v-else class="d-flex flex-column align-center my-16">
          <v-progress-circular
            indeterminate
            color="secondary"
            :size="100"
          ></v-progress-circular>
        </v-container>
      </v-container>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import Footer from "@/components/Shared/Footer.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
// import SearchField from "@/components/User/Shared/SearchField.vue";
import ServiceCardList from "@/components/User/Shared/ServiceCardList.vue";
import { GET_USER_QUOTATION_REQUESTS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { mapState } from "vuex";
import { convertDateToCstTimeZoneUnix } from "@/Utils/dateConverter.js";

export default {
  name: "MyRequests",
  components: {
    Navbar,
    UserNavbar,
    Footer,
    SearchBar,
    // SearchField,
    ServiceCardList,
  },
  data() {
    return {
      tabs: [
        {
          tabTitle: "Todas",
          title: "Todas las solicitudes",
        },
        {
          tabTitle: "Recibidas",
          title: "Solicitudes recibidas",
        },
        {
          tabTitle: "Pendientes",
          title: "Solicitudes pendientes",
        },
        {
          tabTitle: "Rechazadas",
          title: "Solicitudes rechazadas",
        },
      ],
      tab: 0,
      requests: [],
      loading: true,
    };
  },
  computed: mapState({
    user(state) {
      if (state.user) {
        return state.user;
      }
    },
    filterRequests() {
      return this.requests.filter((req) => {
        switch (this.tab) {
          case 0:
            return (
              req.status === "approved" ||
              req.status === "pending" ||
              req.status === "rejected"
            );
          case 1:
            return req.status === "approved";
          case 2:
            return req.status === "pending";
          case 3:
            return req.status === "rejected";
          default:
            return false;
        }
      });
    },
  }),
  watch: {
    user: {
      async handler(user) {
        if (!user) return;
        this.fetchQuotationRequests(user.id);
      },
      immediate: true,
    },
  },
  methods: {
    async fetchQuotationRequests(userId) {
      const { data, errors } = await useQuery(GET_USER_QUOTATION_REQUESTS, {
        userId: parseInt(userId),
      });
      if (data) {
        this.requests = this.formatQuotationRequests(data.userQuotations);
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
    formatQuotationRequests(requests) {
      return requests.map((request) => {
        return {
          id: request.quotationRequest.id,
          name: request.service.name,
          eventName: request.quotationRequest.eventType,
          provider: request.provider.name,
          rating:
            request.service.serviceComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / request.service.serviceComment.length,
          reviews: request.service.serviceComment.length,
          date: convertDateToCstTimeZoneUnix(
            request.quotationRequest.insertedAt
          ),
          status: request.quotationRequest.status,
          image_url: request.service.serviceImage[0].url,
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.bg-purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 255%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.subtitle {
  font-size: 30px;
}
</style>
